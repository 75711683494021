/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

	huntableItems: [],

	// All pages
	'common': {
		init: function() {
			// Set up the hunt
			this.setupHunt.call(this);
      this.huntScrollCallback();
      this.mobileMenu();
		},

		finalize: function() {
			// Trigger a scroll
		},

    /*
     * Mobile Menu
     */

     mobileMenu: function(){
       jQuery('.open-nav').click(function(){
        jQuery('body').toggleClass('mobile-nav-active');//
       });
     },

		/**
		 * Collect a list of huntable elements
		 */
		setupHunt: function() {
			// Get huntable elements
			this.huntableItems = [].slice.call(document.querySelectorAll('.huntable'));
			if(this.huntableItems.length > 0) {
				window.addEventListener('scroll', this.huntScrollCallback.bind(this), {passive: true});
			}
		},

		/**
		 * Scroll callback to check for elements in view
		 */
		huntScrollCallback: function() {
			// Loop over the huntable items
			this.huntableItems.forEach(function(oElement) {
				// Check if the element is in view
				if(!this.elementInViewport(oElement, 40)) {
					return;
				}

				oElement.classList.remove('huntable');
			}, this);
		},

		/**
		 * Check if an element is visible between the top and bottom of the
		 * viewport (or is covering the viewport)
		 */
		elementInViewport: function(oElement, nPercentThreshold) {
			nPercentThreshold = (!isNaN(nPercentThreshold)) ? Math.min(100, Math.max(0, nPercentThreshold)) : 0;
			nOffset = (oElement.offsetHeight / 100) * nPercentThreshold;

			const oClientRect = oElement.getBoundingClientRect();
			const bTop = oClientRect.top >= 0 && oClientRect.top <= (window.innerHeight - nOffset);
			const bBottom = oClientRect.bottom >= nOffset && oClientRect.bottom <= window.innerHeight;
			const bCovering = oClientRect.top <= 0 && oClientRect.bottom >= window.innerHeight;

			return (bTop || bBottom || bCovering);
		}

	},

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
	$(document).ready(UTIL.loadEvents);












// Identifies breakpoints
function fnFullTeamCalcSizeBracket() {
	if(window.innerWidth >= 1024) {
			return 4;
	} else if(window.innerWidth >= 768) {
			return 3;
	} else if(window.innerWidth >= 540) {
			return 2;
	}

	return 1;
}

/**
*
*/
function fnAccordionSingleClicked(oEvent) {
	oEvent.preventDefault();

	var oReadMoreButton = $(oEvent.currentTarget);
	var oCurrentSingle = oReadMoreButton.closest('.framework-accordion__single');
	var oSection = oReadMoreButton.closest('.framework-accordion');
	var oAllSingles = $('.framework-accordion__single', oSection);
	var oAllContentContainers = $('.content-container', oSection);

	// Is the single already open?
	var bAlreadyOpen = oCurrentSingle.hasClass('active');

	// Remove active
	oAllSingles.removeClass('active');
	oAllContentContainers.removeClass('active');

	// If the single wasn't already open
	if(!bAlreadyOpen) {
			// Open it
			oCurrentSingle.addClass('active');

			// Get the content that matches the single
			var sMemberContentClass = '#' + oReadMoreButton.attr('id') + '-content';
			var oMemberContent = $(sMemberContentClass);

			// Clone the content into the next content container
			var oContentContainer = oCurrentSingle.nextAll('.content-container:first');
			oContentContainer.html(oMemberContent.clone());
			oContentContainer.addClass('active');
	}
}

/**
* Close the active team member
*/
function fnCloseAccordionContent(oEvent) {
	var oButton = $(oEvent.currentTarget);
	var oSection = oButton.closest('section');

	$('.framework-accordion__single', oSection).removeClass('active');
	$('.content-container', oSection).removeClass('active');
}


/**
*
*/
function fnCheckAccordionBreakpoints(oSection) {
	oSection = $(oSection);
	var sDataKey = 'size-bracket';

	// Ignore if still in same size bracket
	var nPreviousSize = parseInt(oSection.data(sDataKey), 10);
	var nCurrentSize = fnFullTeamCalcSizeBracket();
	if(nPreviousSize === nCurrentSize) {
			return;
	}
	oSection.data(sDataKey, nCurrentSize);

	// Get all the singles
	var oSingles = $('.framework-accordion__single', oSection);
	if(oSingles.length === 0) {
			return;
	}

	// Deactivate any active single
	oSingles.removeClass('active');

	// Remove all content containers
	$('.content-container', oSection).remove();

	// Get the top pixel position of the first single
	var nRowTop = Math.floor(oSingles.eq(0).offset().top);

	// Loop over all the singles
	oSingles.each(function(i, oSingle) {
			oSingle = $(oSingle);

			// Get the top pixel position of the CURRENT single
			var nCurrentTop = Math.floor(oSingle.offset().top);

			// If the current top doesn't match the row top...
			if(nCurrentTop !== nRowTop) {
					// Insert the content container above the current single
					($('<div>', {
							class: 'content-container'
					})).insertBefore(oSingle);

					// Save the new row top
					nRowTop = Math.floor(oSingle.offset().top);
			}
	});

	//
	$('.framework-accordion__wrapper', oSection).append($('<div>', {
			class: 'content-container'
	}));
}

function fnSetupFrameworkAccordion(i, oSection) {
	oSection = $(oSection);

	// Fudge content container positions on window resize
	$(window).on('resize', function() {
			fnCheckAccordionBreakpoints(oSection[0]);
	});

	// Clicking a team member will open their content container
	oSection.on('click', '.framework-accordion__read-more', fnAccordionSingleClicked);

	// Clicking the close button inside a content container will close it!
	oSection.on('click', '.content-container .close', fnCloseAccordionContent);

	// Run the first update
	fnCheckAccordionBreakpoints(oSection[0]);
}

//
$('section.framework-accordion').each(fnSetupFrameworkAccordion);








$('header.header').on('click', '.mobile-nav__primary .wpml-ls-current-language > a', function(oEvent) {
	oEvent.preventDefault();
	$(oEvent.currentTarget).closest('.menu-item').toggleClass('open');
});

$(document).ready(function(){
  var ajaxData = {
    'action': 'add_analytics_datalayer',
  };

  $.ajax({
    'data': ajaxData,
    'url': '/wp-admin/admin-ajax.php',
    'success': function(response) {
      $('head').append(response);
    }
  });
});

})(jQuery); // Fully reference jQuery after this point.
